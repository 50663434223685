var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loaded)?_c('div',{class:[_vm.$route.name === 'Home' ? 'is-home' : false]},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.$route.name === 'Home' && !_vm.menuStatus),expression:"$route.name === 'Home' && !menuStatus"}],ref:"status",class:['status']},[_c('Grid',{key:_vm.currentSlideIndex,attrs:{"col":"auto"}},_vm._l((_vm.filteredContents),function(content,index){return _c('div',{key:("progress-" + index),staticClass:"bar"},[_c('div',{ref:"progressRef",refInFor:true,staticClass:"progress",staticStyle:{"transform":"scaleX(0)"}})])}),0)],1),_c('div',{ref:"slider",staticClass:"slider"},[_vm._l((_vm.post.acf.navigation),function(nav,parentIndex){return _c('section',{key:nav.pillar_page,ref:"slideRef",refInFor:true,staticClass:"slide",attrs:{"data-index":parentIndex}},_vm._l((nav.content),function(content,index){return _c('div',{key:index,staticClass:"pillar",attrs:{"data-index":(content.custom && content.is_video) ||
            (!content.custom && content.project) ||
            (content.custom && content.layout_custom === 'slide') ||
            ((_vm.isMobile || _vm.isTouch) && content.layout_custom !== 'logo')
            ? index
            : null}},[(content.custom)?[(content.is_reel && content.is_video)?[_c('div',{ref:"itemRef",refInFor:true,staticClass:"item item-slide",attrs:{"data-page":"april","data-layout":"video"}},[(!_vm.isMobile && !_vm.isTouch)?_c('div',{class:[
                  'title-wrapper',
                  _vm.$route.name !== 'Home' ? 'title-wrapper-hide' : false,
                  _vm.$route.meta.archive || _vm.menuStatus ? 'title-wrapper-hide' : false ]},[_c('div',{staticClass:"title"},[_c('Grid',{attrs:{"col":{ default: 12, m: 6 }}},[_c('div'),(content.title !== '')?_c('Title',{staticClass:"title title-fix",attrs:{"data":{
                        value: content.title,
                        size: 'm',
                        tag: 'h2',
                      }}}):_vm._e()],1)],1)]):_vm._e(),_c('Cover',{ref:"reelCover",refInFor:true,attrs:{"data":content.media,"home":true,"april":true,"cursor":content.label_cta ? content.label_cta : 'Explore'}})],1)]:(content.is_video)?[_c('div',{ref:"itemRef",refInFor:true,staticClass:"item item-slide item-slide-video",attrs:{"data-layout":content.media.type,"data-page":nav.pillar_taxonomy.slug}},[(content.layout_custom === 'slide')?[_c('div',{staticClass:"item-slide-new-wrapper"},[_c('router-link',{attrs:{"to":content.link ? content.link.url : nav.pillar_page}},[_c('div',{staticClass:"item-slide-new-text"},[_c('Flex',{attrs:{"align":'v-center'}},[_c('Spacer',{attrs:{"x":"s","top":"s","bottom":{ default: 's', m: 'm' }}},[_c('Richtext',{attrs:{"data":{
                              value: content.richtext,
                              size: _vm.isMobile ? 'm' : 'l',
                              expanded: content.text_wrapper === 'full',
                            }}})],1)],1)],1),_c('Cover',{attrs:{"data":content.media,"home":true,"cursor":content.label_cta ? content.label_cta : 'Explore'}})],1)],1)]:[_c('router-link',{attrs:{"to":nav.pillar_page}},[(!_vm.isMobile && !_vm.isTouch)?_c('div',{class:[
                      'title-wrapper',
                      _vm.$route.name !== 'Home' ? 'title-wrapper-hide' : false,
                      _vm.$route.meta.archive || _vm.menuStatus ? 'title-wrapper-hide' : false ]},[_c('div',{staticClass:"title"},[_c('Grid',{attrs:{"col":{ default: 12, m: 6 }}},[_c('div'),(content.title !== '')?_c('Title',{staticClass:"title title-fix",attrs:{"data":{
                            value: content.title,
                            size: 'm',
                            tag: 'h2',
                          }}}):_vm._e()],1)],1)]):_vm._e(),_c('Cover',{attrs:{"data":content.media,"home":true,"cursor":content.label_cta ? content.label_cta : 'Explore'}})],1)]],2)]:(!content.is_video && content.layout_custom === 'slide')?[_c('div',{ref:"itemRef",refInFor:true,staticClass:"item-slide item-slide-new",style:({
                '--background': content.background_color,
                '--color': content.text_color,
              }),attrs:{"data-layout":"text-slide","data-page":nav.pillar_taxonomy.slug}},[_c('router-link',{attrs:{"to":content.link ? content.link.url : nav.pillar_page,"data-cursor":content.label_cta ? content.label_cta : 'Explore'}},[_c('div',{staticClass:"item-slide-new-text"},[_c('Flex',{attrs:{"align":'center'}},[_c('Spacer',{attrs:{"x":"s","top":"s","bottom":{ default: 's', m: 'm' }}},[_c('Richtext',{attrs:{"data":{
                          value: content.richtext,
                          size: 'xxl',
                          expanded: content.text_wrapper === 'full',
                        }}})],1)],1)],1)])],1)]:(!content.is_video && content.layout_custom !== 'logo')?[_c('div',{ref:_vm.isMobile || _vm.isTouch ? 'itemRef' : null,refInFor:true,staticClass:"item-text item-slide t-center",style:({
                '--background': content.background_color,
                '--color': content.text_color,
              }),attrs:{"data-layout":"text","data-page":nav.pillar_taxonomy.slug}},[_c(content.link && content.link.url && content.link.url.startsWith('http')
                    ? 'a'
                    : 'router-link',{tag:"component",attrs:{"to":content.link && content.link.url && !content.link.url.startsWith('http')
                    ? content.link.url
                    : undefined,"href":content.link && content.link.url && content.link.url.startsWith('http')
                    ? content.link.url
                    : undefined,"target":content.link && content.link.url && content.link.url.startsWith('http')
                    ? '_blank'
                    : undefined}},[_c('Flex',{attrs:{"align":"center"}},[(!_vm.isMobile)?_c('Spacer',{staticClass:"marquee-text-wrapper",attrs:{"top":"s","bottom":{ default: 's', m: 'm' }}},[_c('marquee-text',{attrs:{"repeat":5}},[_c('Richtext',{attrs:{"data":{ value: content.richtext }}})],1)],1):_c('Spacer',{attrs:{"x":"s","top":"s","bottom":{ default: 's', m: 'm' }}},[_c('Richtext',{attrs:{"data":{ value: content.richtext }}})],1)],1)],1)],1)]:(
              !content.is_video && content.layout_custom === 'logo' && !_vm.isMobile && !_vm.isTouch
            )?[_c('router-link',{staticClass:"item-text item-slide item-logo t-center",attrs:{"to":"/projects","data-layout":"logo"}},[_c('Spacer',{attrs:{"y":"l"}},[_c('Marquee',{attrs:{"items":content.logo_clients,"index":index,"direction":index % 2 === 0 ? 'left' : 'right'}})],1)],1)]:_vm._e()]:(content.project)?[_c('div',{ref:"itemRef",refInFor:true,staticClass:"item item-slide",attrs:{"data-page":_vm.projects[content.project.ID].slug,"data-layout":content.media_project || 'video'}},[_c('router-link',{attrs:{"to":_vm.projects[content.project.ID].link}},[(!_vm.isMobile && !_vm.isTouch)?_c('div',{class:[
                  'title-wrapper',
                  _vm.$route.name !== 'Home' ? 'title-wrapper-hide' : false,
                  _vm.$route.meta.archive || _vm.menuStatus ? 'title-wrapper-hide' : false ]},[_c('div',{staticClass:"title"},[_c('Grid',{attrs:{"col":{ default: 12, m: 6 }}},[_c('div'),_c('Title',{staticClass:"title title-fix",attrs:{"data":{
                        value: _vm.projects[content.project.ID].title.rendered,
                        size: 'm',
                        tag: 'h2',
                      }}})],1)],1)]):_vm._e(),(content.media_project === 'image')?_c('Cover',{attrs:{"data":Object.assign({}, _vm.projects[content.project.ID].acf.cover,
                  {type: 'image'}),"home":true,"cursor":content.label_cta ? content.label_cta : 'Explore'}}):_vm._e(),(_vm.projects[content.project.ID].acf.cover)?_c('Cover',{attrs:{"data":_vm.projects[content.project.ID].acf.cover,"home":true,"cursor":content.label_cta ? content.label_cta : 'Explore'}}):_vm._e()],1)],1)]:_vm._e()],2)}),0)}),_c('section',{class:['slide', 'slide-clone', 'slide-clone-first'],attrs:{"data-index":"0"}},[_c('div',{ref:"slideCloneFirst",staticClass:"pillar",attrs:{"data-index":"0"}})]),_c('section',{class:['slide', 'slide-clone', 'slide-clone-last'],attrs:{"data-index":"-1"}},[_c('div',{ref:"slideCloneLast",staticClass:"pillar",attrs:{"data-index":"-1"}})])],2),((_vm.isMobile || _vm.isTouch) && _vm.$route.name === 'Home')?_c('div',{directives:[{name:"touch",rawName:"v-touch:swipe.right",value:(function () { return _vm.setCurrentByNav(false); }),expression:"() => setCurrentByNav(false)",arg:"swipe",modifiers:{"right":true}},{name:"touch",rawName:"v-touch:swipe.left",value:(function () { return _vm.setCurrentByNav(true); }),expression:"() => setCurrentByNav(true)",arg:"swipe",modifiers:{"left":true}},{name:"touch",rawName:"v-touch:swipe.top",value:(_vm.goTo),expression:"goTo",arg:"swipe",modifiers:{"top":true}}],class:['navigation', _vm.videoEmbed ? 'navigation-hide' : false]},[_c('div',{staticClass:"btn prev",on:{"click":function () { return _vm.setCurrentByNav(false); }}}),_c('div',{staticClass:"btn next",on:{"click":function () { return _vm.setCurrentByNav(true); }}}),_c('div',{staticClass:"cta",on:{"click":_vm.goTo}},[_c('Spacer',{attrs:{"bottom":"s"}},[_c('Title',{key:_vm.ctaMobile,staticClass:"title title-fix",attrs:{"data":{
            value: _vm.ctaMobile,
            size: 's',
            tag: 'h3',
          }}})],1),_c('Icon',{attrs:{"name":"cta"}})],1)]):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }