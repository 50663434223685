<template>
  <nav
    :class="[
      'nav',
      $route.name === 'Home' && !menuStatus ? 'is-home' : 'is-single',
      videoEmbed ? 'nav-hide' : false,
      menuStatus ? 'nav-white' : false,
    ]"
  >
    <div
      ref="slider"
      class="slider"
    >
      <div
        v-for="(item, index) in navigation"
        :key="item.id"
        ref="items"
        :class="['slide']"
        :data-slug="item.slug"
        :data-main="item.acf.main"
      >
        <div
          class="svg"
          @click="setSlide(index, true)"
        >
          <inline-svg
            :src="$route.name === 'Home' && item.acf.logo_home ? item.acf.logo_home : item.acf.logo"
          />
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex';
import Flickity from 'flickity';

import 'flickity/dist/flickity.min.css';

export default {
  name: 'Menu',
  data() {
    return {
      nav: null,
      initialIndex: 0,
      currentIndex: 0,
      x: 0,
      force: false,
      prevIndex: 0,
    };
  },
  computed: {
    ...mapGetters(['menu', 'homeSlideIndex', 'videoEmbed', 'menuStatus']),
    navigation() {
      const nav = this.menu('navigation');
      return nav ? nav.items : null;
    },
  },
  watch: {
    $route(to, from) {
      if (
        (to.params && to.params.pillar === 'projects')
        || (from.params && from.params.pillar === 'projects' && to.name === 'Home')
      ) {
        this.$nextTick(() => {
          this.nav.resize();
          this.nav.reposition();
          if (to.name === 'Home') {
            this.setSlide(this.prevIndex);
          }
          if (to.params && to.params.pillar === 'projects') {
            this.menuPrevIndex = 0;
            this.setSlide(0);
          }
        });
      } else {
        if (to.name !== 'Home') {
          let next = 0;
          let signal = false;
          this.$refs.items.forEach((el, index) => {
            if (to.path.split('/').includes(el.dataset.slug)) {
              next = index;
              signal = true;
            } else if (el.dataset.main && !signal) {
              next = index;
            }
          });
          this.prevIndex = next;
          this.setSlide(next);
        } else if (from && from.name) {
          let signal = false;
          this.$refs.items.forEach((el) => {
            if (from.path.split('/').includes(el.dataset.slug)) {
              signal = true;
            }
          });

          if (!signal) this.setSlide(0);
        }
        this.$nextTick(() => {
          this.nav.resize();
          this.nav.reposition();
        });
      }
    },
    homeSlideIndex(val) {
      this.prevIndex = this.homeSlideIndex;
      if (this.force) return;
      this.setSlide(val);
    },
    menuStatus(val) {
      if (val) {
        this.menuPrevIndex = this.prevIndex;
        this.$nextTick(() => {
          this.nav.resize();
          this.nav.reposition();
          this.setSlide(0);
        });
      } else {
        this.$nextTick(() => {
          this.nav.resize();
          this.nav.reposition();
          if (this.menuPrevIndex >= 0) {
            this.setSlide(this.menuPrevIndex);
          } else {
            this.setSlide(this.prevIndex);
          }
          // this.menuPrevIndex = 0;
        });
      }
    },
  },
  mounted() {
    let signal = false;
    this.$refs.items.forEach((el, index) => {
      if (this.$route.path.split('/').includes(el.dataset.slug)) {
        this.initialIndex = index;
        signal = true;
      } else if (el.dataset.main && this.$route.name !== 'Home' && !signal) {
        this.initialIndex = index;
      }
    });
    this.currentIndex = this.initialIndex;
    this.prevIndex = this.currentIndex;

    this.nav = new Flickity(this.$refs.slider, {
      cellAlign: 'center',
      cellSelector: '.slide',
      wrapAround: true,
      prevNextButtons: false,
      pageDots: false,
      draggable: false,
      initialIndex: this.initialIndex,
      selectedAttraction: 0.08,
      friction: 0.5,
    });

    this.$nextTick(() => {
      this.nav.resize();
      this.nav.reposition();
    });

    this.nav.on('change', () => {
      this.nav.positionCells();
    });

    if (this.$route.params.pillar === 'projects') {
      this.setSlide(0);
    }
  },
  methods: {
    setSlide(index, force) {
      if (this.currentIndex === index) return;
      if (this.videoEmbed) return;
      if (force) {
        this.force = true;
        this.$store.commit('SET_HOME_PILLAR_INDEX_NAV', parseInt(index, 10));
      } else {
        this.$store.commit('SET_HOME_PILLAR_INDEX_NAV', -1);
      }
      this.$store.commit('SET_FORCE', force);
      this.currentIndex = index >= this.$refs.items.length ? 0 : index;
      // this.slideClass();
      this.nav.select(this.currentIndex);
      this.force = false;
      setTimeout(() => {
        this.$store.commit('SET_FORCE', false);
      }, 200);
    },
  },
};
</script>

<style lang="scss" scoped>
nav {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: var(--logo-height-selected);
  width: calc((var(--slide-width) * 4) + var(--slide-width-selected));
  z-index: 20;

  &.nav-white {
    color: $white;
  }

  @include mq($until: m) {
    width: calc((var(--slide-width) * 2) + var(--slide-width-selected));
  }

  @include mq($from: s, $and: $isTouch) {
    width: calc((var(--slide-width) * 2) + var(--slide-width-selected));

    .is-home & {
      width: calc((var(--slide-width) * 2) + var(--slide-width-selected));
    }
  }

  &.nav-hide {
    pointer-events: none !important;

    * {
      pointer-events: none !important;
    }
  }

  .slider {
    width: 100%;
    height: var(--logo-height-selected);
    overflow: hidden;
    pointer-events: none;
  }

  .slide {
    width: var(--slide-width);
    height: var(--logo-height-selected);
    visibility: hidden;
    pointer-events: auto;
    text-align: center;
    cursor: pointer;

    &.is-selected {
      width: var(--slide-width-selected);
      visibility: visible;

      .svg {
        svg {
          height: var(--logo-height-selected) !important;
        }
      }
    }

    &:not(.is-selected) {
      opacity: 0.6;

      &:hover {
        opacity: 0.9;
      }
    }
  }

  .slide:not(.is-selected):not(.is-next):not(.is-previous) {
    pointer-events: none;
    opacity: 0.2;
  }

  .svg {
    margin: 0 auto;

    svg {
      width: auto !important;
      max-width: 100%;
      height: var(--logo-height) !important;
      // fill: currentColor !important;
    }
  }

  &.is-home {
    .slider {
      .slide {
        visibility: visible;
        pointer-events: auto;
      }
    }
  }

  &.is-single {
    .slider {
      .slide {
        .svg {
          ::v-deep svg {
            fill: currentColor !important;

            * {
              fill: currentColor !important;
            }
          }
        }
      }
    }
  }

  .slider {
    .slide {
      pointer-events: none;
    }
  }
}
</style>
<style lang="scss">
/* .svg {
  svg {
    * {
      fill: currentColor !important;
    }
  }
} */
</style>
